var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"pop-up-notice",attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-button fr"},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:notice:edit']),expression:"['app:notice:edit']"}],attrs:{"size":"small"},on:{"click":function($event){return _vm.showSubDialog('visibleWxAppDialog', 'wxAppDialogRef')}}},[_vm._v(" 管理小程序原始ID ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:popup:edit']),expression:"['app:popup:edit']"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.showDialog()}}},[_vm._v("添加推送 ")])],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"label":"接收人群","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.showDialog(row.id, row,'view')}}},[_vm._v(" "+_vm._s(row.userType === 0 ? '全部' : (row.userType === 3 ? '教职工' : '学生'))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"接收范围","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.msgArea, _vm.msgAreaList))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"msgProfile","label":"弹窗内容","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.msgImg)?_c('el-image',{attrs:{"width":"260px","height":"520px","src":("" + _vm.$fileUrl + (row.msgImg)),"preview-src-list":[_vm.$fileUrl + row.msgImg],"fit":"contain"}}):_c('span',{staticClass:"font-grey"},[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"label":"是否弹窗","width":"140","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-color":"var(--color-success)","active-value":1,"active-text":"是","inactive-text":"否","inactive-value":0},on:{"change":function($event){return _vm.handleEnable($event, row)}},model:{value:(row.enable),callback:function ($$v) {_vm.$set(row, "enable", $$v)},expression:"row.enable"}})]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注"}}),_c('el-table-column',{attrs:{"label":"操作","width":"100","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:popup:edit']),expression:"['app:popup:edit']"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.showDialog(row.id,row,'edit')}}},[_vm._v("编辑 ")]),(!_vm.$store.state.login.userInfo.collegeId || _vm.$store.state.login.userInfo.collegeId === row.collegeId)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['app:popup:del']),expression:"['app:popup:del']"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(_vm.delPopUpNoticeApi, row.id, "这条首页弹窗推送")}}},[_vm._v("删除 ")])]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('pop-up-notice-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog,"msg-area-list":_vm.msgAreaList},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e(),(_vm.visibleWxAppDialog)?_c('wx-app-id-dialog',{ref:"wxAppDialogRef",attrs:{"visible":_vm.visibleWxAppDialog},on:{"update:visible":function($event){_vm.visibleWxAppDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }