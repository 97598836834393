import request from '@/service/request'
// 首页弹窗 通知
// 首页弹窗page
export const pagePopUpNoticeApi = (params) => {
  return request.post('/app/windowMsg/page', params)
}

// 首页弹窗 保存
export const savePopUpNoticeApi = (params) => {
  return request.post('/app/windowMsg/save', params)
}

// 首页弹窗 发送
export const sendPopUpNoticeApi = (params) => {
  return request.post('/app/windowMsg/sendMsg', params)
}

/* 首页弹窗 删除
*  id {string} 通知id
* */
export const delPopUpNoticeApi = (id) => {
  return request.post(`/app/windowMsg/deleteMsg/${id}`)
}

/* 首页弹窗 根据id获取
*  id {string} 通知id
* */
export const getPopUpNoticeByIdApi = (id) => {
  return request.post(`/app/windowMsg/getById/${id}`)
}
