<template>
  <table-view class="pop-up-notice" :total="total" :query-info.sync="queryInfo" v-loading="loading"
              @reload-table="renderTable">
    <template #header>
      <!--      <view-search-form-->
      <!--        :query-info.sync="queryInfo"-->
      <!--        :tool-list="['keyword']"-->
      <!--        :show-clear-btn="false"-->
      <!--        style="display:inline-block;"-->
      <!--        @on-search="renderTable(1)"-->
      <!--      />-->
      <div class="header-button fr">
        <el-button v-permission="['app:notice:edit']" size="small"
                   @click="showSubDialog('visibleWxAppDialog', 'wxAppDialogRef')">
          管理小程序原始ID
        </el-button>
        <el-button v-permission="['app:popup:edit']" type="primary" size="small" @click="showDialog()">添加推送
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column label="接收人群" width="80">
        <template v-slot="{ row }">
          <el-button type="primary" size="mini" @click="showDialog(row.id, row,'view')">
            {{ row.userType === 0 ? '全部' : (row.userType === 3 ? '教职工' : '学生') }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="接收范围" width="80">
        <template v-slot="{ row }">
          {{ $http.getParamName(row.msgArea, msgAreaList) }}
        </template>
      </el-table-column>
      <el-table-column prop="msgProfile" label="弹窗内容" width="280">
        <template v-slot="{ row }">
          <el-image v-if="row.msgImg" width="260px" height="520px" :src="`${$fileUrl}${row.msgImg}`"
                    :preview-src-list="[$fileUrl + row.msgImg]" fit="contain" />
          <span class="font-grey" v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="是否弹窗" width="140" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.enable" active-color="var(--color-success)" :active-value="1" active-text="是"
                     inactive-text="否" :inactive-value="0" @change="handleEnable($event, row)" />
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="100" fixed="right" align="center">
        <template v-slot="{ row }">
          <el-button v-permission="['app:popup:edit']" type="primary" size="small"
                     @click="showDialog(row.id,row,'edit')">编辑
          </el-button>
          <!--  只能编辑管辖学院的通知 -->
          <template
            v-if="!$store.state.login.userInfo.collegeId || $store.state.login.userInfo.collegeId === row.collegeId">
            <el-button v-permission="['app:popup:del']" size="mini" type="danger"
                       @click="del(delPopUpNoticeApi, row.id, `这条首页弹窗推送`)">删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <pop-up-notice-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
                          :msg-area-list="msgAreaList"
                          @on-close="renderTable" />

    <!-- 小程序原始ID管理 -->
    <wx-app-id-dialog ref="wxAppDialogRef" :visible.sync="visibleWxAppDialog" v-if="visibleWxAppDialog" />
  </table-view>
</template>
<script>
import tableView from '@/vue/mixins/table-view'
import PopUpNoticeDialog from '@/views/pages/appManage/pop-up-notice/component/popUpNoticeDialog.vue'
import { delPopUpNoticeApi, pagePopUpNoticeApi, savePopUpNoticeApi } from '@/api/pop-up-notice-api'
import WxAppIdDialog from '@/views/pages/appManage/notice/component/wxAppIdDialog.vue'

export default {
  name: 'popUpNotice',
  components: {
    WxAppIdDialog,
    PopUpNoticeDialog
  },
  mixins: [tableView],
  data() {
    return {
      msgAreaList: [], // 发送类型
      visibleWxAppDialog: false
    }
  },
  mounted() {
    this.renderTable(1)
    this.$http.getParamListByKey('sendType', this, 'msgAreaList')
  },
  methods: {
    delPopUpNoticeApi, // 删除api
    // 获取消息通知列表
    renderTable(pageNum) {
      this.getTableData(pagePopUpNoticeApi, pageNum)
    },

    /*
    * 显示弹窗
    * visibleDialog {boolean} 控制弹窗显示/隐藏
    * id {string} 一般为row的id,编辑状态要传
    * preTitle {string} 用于弹窗标题
    * title {string} 用于弹窗标题后缀
    * view=查看， edit= 编辑  add=新增
     */
    showDialog(id = '', row, type) {
      if (!row) row = ''
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.type = type
        if (type === 'view') {
          this.$refs.dialogRef.preTitle = '查看'
        } else if (type === 'edit') {
          this.$refs.dialogRef.preTitle = '编辑'
          Object.keys(this.$refs.dialogRef.formData).forEach(key => {
            this.$refs.dialogRef.formData[key] = row[key]
          })
        } else {
          this.$refs.dialogRef.type = 'add'
          this.$refs.dialogRef.preTitle = '推送'
          this.$refs.dialogRef.title = row
        }
        this.$refs.dialogRef.id = id
        // 深拷贝原始formData
        this.$refs.dialogRef.setInitData()
      })
    },
    // 是否弹窗
    handleEnable(val, row) {
      this.$confirm(`<p class="confirm-message">是否${val ? '<b class="success">启用</b>' : '<b class="danger">禁用</b>'}此首页弹窗？<br/>（请注意：所有应用程序用户只会看到一个首页弹窗。若遇到发送范围重复的情况时，将按照<b>最新的启用时间</b>进行推送。）</p>`, '设置首页弹窗', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          await savePopUpNoticeApi({
            id: row.id,
            enable: val
          })
          this.$message.success('设置成功!')
        } catch (e) {
          this.$message.error('设置失败，请重试！')
          row.enable = val ? 0 : 1
        }
      }).catch(() => {
        this.$message('已取消')
        row.enable = val ? 0 : 1
      })
    }
  }
}
</script>
